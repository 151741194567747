































































import { Component } from "vue-property-decorator";
import SumInput from "fs-pb-vue2/components/SumInput.vue";
import { BaseOperationPropertyVue } from "fs-pb-vue2/vueModels/operations/properies/BaseOperationPropertyVue";
import { DeclarationPropertiesTypes } from "fs-pb-vue2/data/enums/declarationPropertiesTypes";
import { Prop, Watch } from "vue-property-decorator";
import PhoneSelect from "./PhoneSelect.vue";
import { PhoneFormat } from "fs-pb-vue2/data/phoneFormat";

@Component({
  components: { SumInput, PhoneSelect }
})
export default class TextProperty extends BaseOperationPropertyVue {

  @Watch('property.Value', { immediate: true })
  valueChanged(val: any): void {
    if (this.property.Declaration.Type === DeclarationPropertiesTypes.Decimal) {
      this.sizeToPrecision();
    }
  }
  
  @Watch('property.PhoneRightValue', { immediate: true })
  rightChanged(val: string): void {
    if (this.phoneWithOutCode !== val)
      this.phoneWithOutCode = val;
  }

  @Watch('phoneWithOutCode', { immediate: true })
  maskedChanged(val: string): void {
    this.property.PhoneRightValue = val;
    this.property.PhoneDisplayValue = val;
  }

  phoneWithOutCode: string = '';

  phoneBlur(): void {    
    this.$refs[this.property.Declaration.Name].focus();
    this.$refs[this.property.Declaration.Name].blur();
    //await this.onBlur();
  }

  sizeToPrecision(): void {
    if (this.property.Declaration.Type === DeclarationPropertiesTypes.Decimal && this.property.Value.length) {
      const stringValue: string = this.property.Value.toString().replaceAll('.', ',');
      const decimalPart: number = stringValue.indexOf(',') !== -1 ? stringValue.length - (stringValue.indexOf(',') + 1) : 0;
      if (decimalPart < this.property.Declaration.Size) {
        if (decimalPart === 0) {
          this.property.Value = new Number(this.property.Value).toFixed(this.property.Declaration.Size);
        } else {
          for (let i = 0; i < this.property.Declaration.Size - decimalPart; i++) {
            this.property.Value += '0';
          }
        }
      }
    }
  }

}
