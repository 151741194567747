var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-card"},[_c('h1',{staticClass:"auth-card--title mb-4 ebs-title"},[_vm._v(" "+_vm._s(_vm.$t("auth.form.ebsTitle"))+" ")]),_c('span',{staticClass:"ebs-desc"},[_vm._v(" "+_vm._s(_vm.$t("auth.form.ebsDescr"))+" ")]),_c('div',{staticClass:"info-wrap mt-4"},_vm._l((_vm.infoArr),function(item,i){return _c('div',{key:i,staticClass:"info-item"},[_c('div',{staticClass:"number"},[_vm._v(" "+_vm._s(i+1)+" ")]),_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s(item === 'third' ? _vm.$t(("auth.form.ebsInfo." + item), { link: 'https://cryptopro.ru/user/' }) : _vm.$t(("auth.form.ebsInfo." + item)))}})])}),0),_c('p',{staticClass:"auth-card--rules mt-2",domProps:{"innerHTML":_vm._s(
    _vm.$t('auth.form.ebsInfo.conditions', {
      remoteRules: _vm.configStore.apiData ? _vm.configStore.apiData.DKBORulesFile : '',
      cardRules: _vm.configStore.apiData ? _vm.configStore.apiData.CardConditions : '',
      tariffs: _vm.configStore.apiData ? _vm.configStore.apiData.EbsTarifs : '',
      personData: _vm.configStore.apiData ? _vm.configStore.apiData.PersonalDataRulesFile : '',
      offerLoyalty: _vm.configStore.apiData ? _vm.configStore.apiData.OfferLoyaltyProgram : '',
      rulesLoyalty: _vm.configStore.apiData ? _vm.configStore.apiData.RulesLoyaltyProgram : ''
    })
  )}}),_c('div',{staticClass:"ebs-submit mt-4"},[_c('v-btn',{staticClass:"fs-main-btn mr-5",attrs:{"loading":_vm.esiaLoading},on:{"click":function($event){return _vm.initEsiaRegistration()}}},[_vm._v(_vm._s(_vm.$t("auth.form.ebsInfo.submit")))]),_c('a',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(_vm._s(_vm.$t("common.back")))])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }